// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useLayoutEffect } from 'react';
import { Outlet, useNavigation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Toaster } from '@plesk/ui-library';
import TopBarProgress from 'react-topbar-progress-indicator';
import Cookie from 'common/cookie';
import { Task, Tooltip } from 'jsw';
import pageData from 'helpers/pageData';
import { useToasts, removeToast } from 'helpers/toasts';

TopBarProgress.config({
    barThickness: 2,
    barColors: {
        0: '#28aade',
        1.0: '#28aade',
    },
    shadowBlur: 0,
    className: 'top-bar-progress',
});

import TASK_PROGRESS_BAR_QUERY from 'queries/TaskProgressBar.graphql';

const AppLayout = () => {
    const navigation = useNavigation();
    const { data: { viewer, config } } = useQuery(TASK_PROGRESS_BAR_QUERY);
    const toasts = useToasts();

    useLayoutEffect(() => {
        if (viewer.type === 'UNDEFINED') {
            return;
        }
        new Task.ProgressBar({
            wsEnabled: config.webSocket.enabled,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        switch (navigation.state) {
            case 'loading': {
                const { status, ...data } = pageData();
                pageData({
                    ...data,
                    status: status?.filter(message => message.isNote),
                });
                break;
            }

            case 'idle': {
                Tooltip.hide();

                window.history.scrollRestoration = 'manual';
                window.scrollTo(0, 0);

                const bottomAnchor = document.getElementById('bottomAnchor');
                if (bottomAnchor) {
                    bottomAnchor.innerHTML = Date.now();
                }
                break;
            }
        }
    }, [navigation.state]);

    return (
        <>
            {navigation.state !== 'idle' ? (
                <TopBarProgress />
            ) : null}
            <Outlet />
            <Toaster toasts={toasts} onToastClose={key => removeToast({ key })} data-type="toaster" />
            {'true' === Cookie.get('debug-testing-mode') ? (
                <>
                    <style type="text/css">
                        {`
                                *, *:before, *:after {
                                    transitions-delay: 0s !important;
                                    transition-duration: 0s !important;
                                    animation-duration: 0s !important;
                                }
                            `}
                    </style>
                    <div id="bottomAnchor" style={{ position: 'fixed', top: 0, zIndex: -1, opacity: 0.001 }} />
                </>
            ) : null}
        </>
    );
};

export default AppLayout;
